import '../App.css';
import { useState, useEffect, useRef } from 'react';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useParams } from "react-router-dom"
import ReportDatatable from '../components/ReportDatatable';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Icon from '@mui/icons-material/Refresh';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TokenValid, TokenRedirect} from "../components/Token";
import {GetDate, GetUSDateString}  from '../components/GetDate';
import dayjs from 'dayjs';

function ReportPage() {
  
  // The Report Name is passed in the URL
  const { reportname } = useParams();

  // This sets the Deactive and Test buttons to checked
  const [hideDeactive, setHideDeactive] = useState(true);
  const [hideTest, setHideTest] = useState(true);
  const [useCached, setUseCached] = useState(true);

  // This is the state of the details button
  const [extraCols, setExtraCols] = useState(false);

  // Hitting the refresh button will increment this, which will cause the report to refresh
  const [refreshCount, setRefresh] = useState(0);

  // This is the previous report name. If it changes, we need to reset the refresh count
  const [prevReport, setPrevReport] = useState("");

  // These are the actual parameters passed to the report object. When you update these the report will refresh
  const [excludedP, setExcludedP] = useState("");
  const [includedP, setIncludedP] = useState("");  
  const [dateStartP, setDateStartP] = useState(dayjs("2023-02-15"));
  const [dateEndP, setDateEndP] = useState(dayjs(GetDate()));

  // These are the parameters for this form that get moved into the actual parameters
  const includeRef = useRef();
  const excludeRef = useRef();
  const [dateStart, setDateStart] = useState(dayjs("2023-02-15"));
  const [dateEnd, setDateEnd] = useState(dayjs(GetDate()));
  
  // Refresh Button: Update the params so that the control refreshes
  function setParams() {
    setRefresh(refreshCount+1);
    setExcludedP(excludeRef.current.value);
    setIncludedP(includeRef.current.value);
    setDateStartP(GetUSDateString(dateStart));
    setDateEndP(GetUSDateString(dateEnd));
  }

  if (prevReport !== reportname) {
    setRefresh(0);
    setPrevReport(reportname);
  }

  // Set some defaults when we change report name
  useEffect(() => {
    setRefresh(0);
    if (reportname === "RptESDDownloadLog") {
      setExtraCols(true)
      setExcludedP("");
      excludeRef.current.value = ""      
      includeRef.current.value = ""
      setIncludedP("");
    }
    if (reportname === "RptExportLicenses") {
      setExtraCols(true)
      setExcludedP("NETLIB");
      excludeRef.current.value = "NETLIB"      
      includeRef.current.value = ""
      setIncludedP("");
    }    
    if (reportname === "RptActivationDataExport") {
        setExtraCols(false)
        setExcludedP("GMMI")
        excludeRef.current.value = "GMMI"
        includeRef.current.value = ""
        setIncludedP("");
      }
    if (reportname === "RptActivations") {
        setExcludedP("");
        excludeRef.current.value = ""        
        includeRef.current.value = ""
        setIncludedP("");
       }

  }, [reportname]
  )

  // Security. This can only be after the useEffects
  if (!TokenValid())
    return(TokenRedirect());  

  // Depending on report name, disable or enable various controls, and set report name in the header
  var enableDeactiveButton = true
  var enableTestButton = true
  var enableCachedButton = true
  var enableExtraCols = true
  var enableIncludeField = true
  var enableExcludeField = true
  var rptHeader = "No Report"

  if (reportname === "RptESDDownloadLog") {
    enableDeactiveButton = false;
    enableCachedButton = false
    enableExtraCols = false;
    enableIncludeField = false
    enableExcludeField = false
    rptHeader = "Downloads"
    }
  if (reportname === "RptActivations") {
    enableDeactiveButton = false
    enableTestButton = false
    enableCachedButton = false
    enableExtraCols = false
    enableIncludeField = false
    enableExcludeField = false
    rptHeader = "Summary"
  }
  if (reportname === "RptExportLicenses") {
    rptHeader = "Licenses"
    enableDeactiveButton = false
    enableCachedButton = false
  }
  if (reportname === "RptActivationDataExport") {
    rptHeader = "Activations"
  }

  return (
    <div className="App">

      <Paper elevation={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={3} md={2} lg={1}>
            <h2>{rptHeader}</h2>
          </Grid>

          <Grid item xs={3} md={2} lg={1}>
            <FormControlLabel control={
              <Switch    
              disabled={!enableDeactiveButton}   
              checked={hideDeactive}
              onChange={(event) => setHideDeactive(event.target.checked)} 
              />
              } label="Hide Deactive" />
          </Grid>

        <Grid item xs={3} md={2} lg={1}>
        <FormControlLabel control={
            <Switch       
              disabled={!enableTestButton}
              checked={hideTest}
              onChange={(event) => setHideTest(event.target.checked)} 
            />
        } label="Hide Test" />        
        </Grid>

        <Grid item xs={3} md={2} lg={1}>
        <FormControlLabel control={
            <Switch       
              disabled={!enableCachedButton}
              checked={useCached}
              onChange={(event) => setUseCached(event.target.checked)} 
            />
        } label="Use Cached" />        
        </Grid>

        <Grid item xs={3} md={2} lg={1}>
        <FormControlLabel control={
            <Switch       
              disabled={!enableExtraCols}
              checked={extraCols}
              onChange={(event) => setExtraCols(event.target.checked)} 
            />
        } label="Add Details" />      
        </Grid>

        <Grid item xs={3} md={2} lg={1}>
        <TextField
          id="excludedText"
          inputRef={excludeRef}
          label="Exclude Companies"
          defaultValue={excludedP}
          helperText="Comma sep."
          variant="filled"
          disabled={!enableExcludeField}
          onKeyDown={(e) => (e.keyCode === 13 ? setParams() : null)}
        /> 
        </Grid>

        <Grid item xs={3} md={2} lg={1}>
        <TextField
          id="includedText"
          inputRef={includeRef}
          label="Include Companies"
          helperText="Comma sep."
          variant="filled"
          disabled={!enableIncludeField}
          onKeyDown={(e) => (e.keyCode === 13 ? setParams() : null)}
        />    
        </Grid>      

        <Grid item xs={3} md={4} lg={1}>
        <DatePicker
          label="Start Date"
          value={dateStart}
          onChange={(newValue) => setDateStart(newValue)}   
          slotProps={{ textField: { 
            InputProps: {
              sx: { fontSize: 15, height:57},
            },
           } }} 
        />
        </Grid>        
        <Grid item xs={3} md={4} lg={1}>
        <DatePicker
          label="End Date"
          value={dateEnd}
          onChange={(newValue) => setDateEnd(newValue)}
          slotProps={{ textField: { 
            InputProps: {
              sx: { fontSize: 15, height:57},
            },
          }}}          
        />
        </Grid>
        
        <Grid item xs={12} md={2} lg={2}>
        <Button 
          id="RefreshButton"
          variant="contained" 
          endIcon={<Icon />} 
          onClick={() => {setParams()}}>
          Refresh
        </Button>     
        </Grid>

        </Grid>
      </Paper>

      <div>
        <ReportDatatable type="xmlelements" name={reportname} useCached={useCached} hideDeactive={hideDeactive} hideTest={hideTest} extraCols={extraCols} refresh={refreshCount} excluded={excludedP} included={includedP} start={dateStartP} end={dateEndP}/>
      </div>
    </div>
  );
}

export default ReportPage;
