import { generateCsv, download } from 'export-to-csv';
import { Box } from '@mui/material';
import CsvConfig from './CsvConfig';

export default class RptESDDownloadLog {

  constructor() {
    if (this.constructor.instance) {
        return this.constructor.instance;
      }
      this.constructor.instance = this;
  }

  // Get Visibility based on details
  getVisibility(extraCols) {
    if (extraCols) {
      return {IPAddress: true, ResultCode:false, isTest:false, isActive:false, isSuccess:false } 
    }
    else {
      return { IPAddress: true, ResultCode:false, isTest:false, isActive:false, isSuccess:false }
    }
  }


  // dataTableColumns - the definition of the columns
  dataTableColumns() {
    return(
      [
        {
          accessorKey: "DateAccessed", 
          header: "DateAccessed",
          filterVariant: 'date-range',
          accessorFn: (originalRow) => new Date(originalRow.DateAccessed),
          Cell: ({ cell }) => (cell.getValue() === '' ? '' : cell.getValue().toLocaleString('sv-SE')),
        },
        {
          accessorKey: "Status", 
          header: "Status",
          filterFn: 'contains',
        },
        {
          accessorKey: "Customer", 
          header: "Customer",
          filterFn: 'contains',
        },    
        {
          accessorKey: "CustomerID", 
          header: "CustomerID",
          filterFn: 'contains',
          Cell: ({ cell }) => (<Box sx={{color:'blue'}}>{cell.getValue()}</Box>),          
          muiTableBodyCellProps: ({ cell, column, table }) => ({
            onDoubleClick: (event) => {
              console.info(event.target.innerText);
              window.open("https://license.netlibsecurity.com/solo/authors/CustomerDetails.aspx?CustomerID="+event.target.innerText, '_blank')
            },
          }),          
        },    
        {
          accessorKey: "LicenseID", 
          header: "LicenseID",
          filterFn: 'contains',
          Cell: ({ cell }) => (<Box sx={{color:'blue'}}>{cell.getValue()}</Box>),          
          muiTableBodyCellProps: ({ cell, column, table }) => ({
            onDoubleClick: (event) => {
              console.info(event.target.innerText);
              window.open("https://license.netlibsecurity.com/solo/authors/orderdetail.asp?Action=View&LicenseID="+event.target.innerText, '_blank')
            },
          }),                
        },    
        {
          accessorKey: "ProductName", 
          header: "ProductName",
          filterFn: 'contains',
        },
        {
          accessorKey: "OptionName",
          header: "OptionName",
          filterFn: 'OptionName',
        },
        {
          accessorKey: "Version", 
          header: "Version",
          filterFn: 'contains',
        },
        {
            accessorKey: "IPAddress", 
            header: "IPAddress",
            filterFn: 'contains',
          }, 
          {
            header: 'Test',
            accessorFn: (originalRow) => (originalRow === undefined || originalRow.Customer === undefined ? 'false' : (originalRow.Customer[0].includes("TEST") ? 'true' : 'false')), //must be strings
            id: 'isTest',
            filterVariant: 'checkbox',
            Cell: ({ cell }) =>
              cell.getValue() === 'true' ? 'Test' : 'Prod',
            size: 170,
          },   
          {
            header: 'Active', // Fake Column
            accessorFn: (originalRow) => "true",
            id: 'isActive',
            filterVariant: 'checkbox',
            Cell: ({ cell }) =>
              cell.getValue() === 'true' ? 'Active' : 'Active',
            size: 170,
          },
          {
            header: 'Success', // Fake Column
            accessorFn: (originalRow) => "true",
            id: 'isSuccess',
            filterVariant: 'checkbox',
            Cell: ({ cell }) =>
              cell.getValue() === 'true' ? 'Success' : 'Success',
            size: 170,
          },      
          {
            header: 'ResultCode', // Fake Column
            accessorFn: (originalRow) => "0",
            id: 'ResultCode',
            Cell: ({ cell }) =>
              cell.getValue() === 'true' ? '0' : '0',
            size: 170,
          }               
      ]
    )
  }

 // handleExportRows - sorted and selected data
 handleExportRows = (rows, extraCols) => {

  if (rows === undefined)
    return;

    const CSVConfig = new CsvConfig();
    const csvConfig = CSVConfig.makeCsvConfig();
    if (!extraCols || extraCols) {
      var rowData;
      rowData = rows.map(
        function(row) {
          return { 
            "DateAccessed" : row.original.DateAccessed, 
            "Status" : row.original.Status, 
            "Customer" : row.original.Customer[0], 
            "CustomerID" : row.original.CustomerID,             
            "LicenseID" : row.original.LicenseID, 
            "ProductName" : row.original.ProductName, 
            "OptionName" : row.original.OptionName, 
            "Version" : row.original.Version, 
            "IPAddress" : row.original.IPAddress,             
          }
        }
      )
    }
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  // handleExportData - all data
  handleExportData = (rows,extraCols) => {

    if (rows === undefined)
    return;

    const CSVConfig = new CsvConfig();
    const csvConfig = CSVConfig.makeCsvConfig();
    if (!extraCols || extraCols) {
      var rowData;
      rowData = rows.map(
        function(row) {
          return { 
            "DateAccessed" : row.DateAccessed, 
            "Status" : row.Status, 
            "Customer" : row.Customer[0], 
            "CustomerID" : row.CustomerID,             
            "LicenseID" : row.LicenseID, 
            "ProductName" : row.ProductName, 
            "OptionName" : row.OptionName, 
            "Version" : row.Version, 
            "IPAddress" : row.IPAddress,             
          }
        }
      )
    }
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);

  };
  
}