import { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { filterData } from './FilterData';
import RptActivations from './RptActivations';
import RptExportLicenses from './RptExportLicenses';
import RptActivationDataExport from './RptActivationDataExport';
import RptESDDownloadLog from './RptESDDownloadLog';
import Loading from './Loading';
var parseString = require('xml2js').parseString;


export default function ReportDataTable(props) {
  const [aryData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState([]);
  const [iniState] = useState({ density: 'compact', pagination: { pageSize: 25 } });
  const [columnFilters, setColumnFilters] = useState([{"id":"isActive","value":"true"}, {"id":"isTest","value":"false"},{"id":"isSuccess","value":"true"}]);
  const [visibility, setVisibility] = useState();
  const [exportRows, setExportRows] = useState();
  const [exportData, setExportData] = useState();
  const [getVisibility, setGetVisibility] = useState();



  // Set columns and other key methods based on report name and object
  const columns = useMemo(
    () => {
            var rptObject;

            if (props.name === "RptActivations") {
              rptObject = new RptActivations();
            }
            else if (props.name === "RptActivationDataExport") {
              rptObject = new RptActivationDataExport();
            }
            else if (props.name === "RptESDDownloadLog") {
              rptObject = new RptESDDownloadLog();
            }
            else if (props.name === "RptExportLicenses") {
              rptObject = new RptExportLicenses();
            }            
            else {
              alert("Did not yet implement " + props.name)
              return []
            }

            setExportRows(()=>rptObject.handleExportRows);
            setExportData(()=>rptObject.handleExportData);
            setGetVisibility(()=>rptObject.getVisibility)
            setData([]);
            return (rptObject.dataTableColumns())
          },
    [props.name]
  );


  // Handle Details Toggle
  useEffect(() => {

    if (getVisibility === undefined)
      return

    if (props.extraCols) {
       // setVisibility( {IPAddress: true, ActivationType:true, ResultCode:true, isTest:false, isActive:false } );
       setVisibility (getVisibility(props.extraCols))
    }
    else {
      // setVisibility({ IPAddress: false, ActivationType:false, ResultCode:false, isTest:false, isActive:false } );
      setVisibility (getVisibility(props.extraCols))
    }

  },[props.extraCols, getVisibility])


  // Handle Hide Test and Hide Deacivated Toggles
  useEffect(() => {

    // Store the filters to be applied here
    var newFilters = [];

    // Keep all of the existing filters, except the ones we'll handle via props
    columnFilters.map(item => {
      var filter = {};      
      filter.id = item.id;
      filter.value = item.value;

      if (filter.id !== "isActive" && filter.id !== "isTest" && filter.id !== "isSuccess") {
        newFilters.push(filter);
      }
      return filter;
    })

    // New filters based on props
    if (props.hideDeactive) {
      newFilters.push({"id":"isActive","value":"true"});
    }
    if (props.hideTest) {
        newFilters.push({"id":"isTest","value":"false"});
    }
    if (!props.extraCols) {
      newFilters.push({"id":"isSuccess","value":"true"});
    }

    // Did we actually change anything? So we do not infinitely rerender
    if (JSON.stringify(columnFilters) !== JSON.stringify(newFilters))
      setColumnFilters(newFilters);

  },[props.hideDeactive, props.hideTest, props.extraCols, columnFilters])

  useEffect(() => {
    if (props.refresh === 0) {
      setIsLoading(false);
      return;
    }

    // Report URL
    var target = "https://license.netlibsecurity.com/solo/authors/REPORTNAME.aspx?WebServiceLogin=True&AuthorID=2451871&UserID=4aaf144d-4176-40fc-8aa1-53a1ce6c2db6&UserPassword=77BD524A44574298113DE7D9B5654E0235F3571ABD657DA39A0A53FA671C07B71F9F8E8D9A4D4AFE1232CC9F614C48E68EFFCDD95EA9C72836046EE47B14E133&ReportType=REPORTTYPE&StartDate=" + props.start + "&EndDate=" + props.end + "&SortBy=1";
    if (props.name === "RptExportLicenses")
      target += "&SearchBy=3&FilterByDate=on"
    
    // Relay to handle browser error
    let location = window.location.href;
    var url = "https://reports.netlibsecurity.com/relay.php?url=" + target.replaceAll("&", "%26");
    if (location.includes("staging"))     
      url = url.replace("reports.", "staging.reports.")

    /*********** TEMP ******** */
    if (props.name === "RptActivationDataExport" && props.useCached)
      url = "https://staging.reports.netlibsecurity.com/dbReport.php?report=ActivationDataExport&StartDate=" + props.start + "&EndDate=" + props.end + "&ninclude="+props.included + "&nexclude="+props.excluded; 


    // Change the report and type
    url = url.replace("REPORTTYPE", props.type); // was xmlelements
    url = url.replace("REPORTNAME", props.name); // was RptActivations.aspx
    setData([]);
    setIsLoading(true);
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/xml'
      })
    })
      .then((res) => res.text())
      .then((res2) => parseString(res2, function(err,  result){ 

        var records = []
        if (props.name === "RptActivations") {          
          setData(result.Activations.Record);
        }
        else if (props.name === "RptActivationDataExport") {
          records = filterData(result, false, false, props.excluded, props.included);
          setIsLoading(false);
          setData(records.ActivationDataExport.Record);
          setSorting([
            {
              id: 'ActivationDate', 
              desc: false,
            }]);
        }
        else if (props.name === "RptESDDownloadLog") {
          records = filterData(result, false, false, props.excluded, props.included);
          setIsLoading(false);
          setData(result.ESDDownloadLog.Record);
          setSorting([
            {
              id: 'DateAccessed', 
              desc: false,
            }]);          
        }
        else if (props.name === "RptExportLicenses") {
          records = filterData(result, false, false, props.excluded, props.included);
          setIsLoading(false);
          setData(result.ExportLicenses.Record);
          setSorting([
            {
              id: 'LicenseEnteredDate', 
              desc: false,
            }]);            
        }
        setIsLoading(false);
      }))
  
      .catch((error) => console.log(error));
  },[props.type,props.name,props.start,props.end,props.included,props.excluded,props.refresh]);  

  
 
  const table = useMaterialReactTable({
    columns,
    data: aryData, 
    enableRowSelection: true,
    enableColumnOrdering:true,
    enableGlobalFilter: false,
    enableClickToCopy: true,
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableHiding: true,
    initialState: iniState,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setVisibility,
    columnFilterDisplayMode: 'popover',
    state: {columnVisibility:visibility, columnFilters, sorting}
  });
 
  if (props.refresh === 0)
    return(<div style={{ marginTop: '30px' }}>Click REFRESH to Load Data</div>)
    
  // Nothing to show?
  if (isLoading || aryData === null) {
    return (<Loading/>);
  }

  return(
    <div style={{ marginTop: '30px' }}    >

      <MaterialReactTable   
          table={table}
      />

      <Button
          // Export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          //onClick={exportData}
          onClick={()=>exportData(aryData, visibility)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() => exportRows(table.getPrePaginationRowModel().rows, props.extraCols)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Filtered Rows
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => exportRows(table.getSelectedRowModel().rows, props.extraCols)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>

    </div>    
  )

}